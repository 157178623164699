import "./contact.css";
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";
import axios from 'axios';
import { useRef } from 'react';

const ContactPage = () => {

  const name = useRef();
  const email = useRef();
  const company = useRef();
  const message = useRef();

  const submitHandler = async (e) => {
    e.preventDefault()
    const newContact = {
      name: name.current.value,
      email: email.current.value,
      company: company.current.value,
      message: message.current.value
    }

    try {
      await axios.post(`https://api.zerodayops.com/api/contact`, newContact);
      window.location.reload();
    } catch (err) {

    }
  }

  return (
    <>
      <NavBar />
      <div className="contactPage-container">
        <div className="contactPage-left">
          <div className="contactPage-left-h1">Contact Us</div>
          <div className="contactPage-formbox">
            <form action="" onSubmit={submitHandler}>
              <input
                name="name"
                type="text"
                className="contactPage-short"
                placeholder="Name"
                ref={name}
              />
              <input
                name="email"
                type="text"
                className="contactPage-short"
                placeholder="Email"
                ref={email}
              />
              <input
                name="subject"
                type="text"
                className="contactPage-feedback-input"
                placeholder="Company"
                ref={company}
              />
              <textarea
                name="text"
                className="contactPage-feedback-input"
                placeholder="Message"
                ref={message}
              ></textarea>
              <input type="submit" value="Request a Quote" className="contactPage-submit" />
            </form>
          </div>
        </div>
        <div className="contactPage-right">
          <br />
          <br />
          <h4><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z" /><circle cx="12" cy="9" r="2.5" /></svg> Gurgaon Office</h4>
          <p style={{ marginLeft: "4.75%" }}>Plot 378-379, Udyog Vihar Phase 4 Rd, near nokia building, Electronic City, Phase IV, Udyog Vihar, Sector 19, Gurugram, Haryana 122015</p>
          <br />
          <br />
          <h5><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg> Sahil Kushwah</h5>
          <p><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" /></svg> sahil.kushwah@zerodayops.com</p>
          <p><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z" /></svg> +91 9718047774</p>
          <br />
          <h5><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg> Gautam Nangia</h5>
          <p><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" /></svg> gautam.nangia@zerodayops.com</p>
          <p><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffff"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z" /></svg> +91 8750539192</p>
        </div>
        <div className="contactPage-cleared"></div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
