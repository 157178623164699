import React from 'react';
import './dashboard.css';

const Dashboard = () => {
  const logoutFunc = () => {
    localStorage.clear();
    window.location.reload();
  }
    return (
        <div className="dashboard-portal-container">
            <div className="dashboard-portal-header">
                <h1>Network Scan</h1>
                <div className="dashboard-portal-header-controls">
                    <button>Configure</button>
                    <button>Audit Trail</button>
                    <button>Launch</button>
                    <button onClick={logoutFunc}>Logout</button>
                </div>
            </div>
            <div className="dashboard-portal-stats">
                <div className="dashboard-portal-stat critical">
                    <span className="dashboard-portal-stat-number">75</span>
                    <span className="dashboard-portal-stat-label">Critical</span>
                </div>
                <div className="dashboard-portal-stat high">
                    <span className="dashboard-portal-stat-number">849</span>
                    <span className="dashboard-portal-stat-label">High</span>
                </div>
                <div className="dashboard-portal-stat medium">
                    <span className="dashboard-portal-stat-number">384</span>
                    <span className="dashboard-portal-stat-label">Medium</span>
                </div>
                <div className="dashboard-portal-stat low">
                    <span className="dashboard-portal-stat-number">31</span>
                    <span className="dashboard-portal-stat-label">Low</span>
                </div>
                <div className="dashboard-portal-stat info">
                    <span className="dashboard-portal-stat-number">225</span>
                    <span className="dashboard-portal-stat-label">Info</span>
                </div>
            </div>
            <div className="dashboard-portal-charts">
                <div className="dashboard-portal-chart">
                    <h2>Operating Systems</h2>
                    <div className="dashboard-portal-piechart"></div>
                </div>
                <div className="dashboard-portal-chart">
                    <h2>Vulnerabilities</h2>
                    <div className="dashboard-portal-piechart"></div>
                </div>
                <div className="dashboard-portal-chart">
                    <h2>Authentication</h2>
                    <div className="dashboard-portal-piechart"></div>
                </div>
            </div>
            <div className="dashboard-portal-vulnerabilities">
                <h2>Vulnerabilities Over Time</h2>
                <div className="dashboard-portal-linechart"></div>
            </div>
            <div className="dashboard-portal-top-vulnerabilities">
                <h2>Top Vulnerabilities</h2>
                <ul className="dashboard-portal-vulnerability-list">
                    <li className="critical">MS17-010: Security Update for Microsoft Windows</li>
                    <li className="critical">MS17-012: Security Update for Microsoft Windows</li>
                    <li className="critical">KB4343899: Windows 7 and Windows Server 2008</li>
                    <li className="critical">MS15-034: Vulnerability in HTTP.sys Could Allow Remote Code Execution</li>
                    <li className="critical">MS16-077: Security Update for WPAD (3165191)</li>
                    <li className="critical">MS17-010: Security Update for Microsoft Windows</li>
                    <li className="critical">Microsoft Malware Protection Engine</li>
                    <li className="critical">MS Security Advisory 4022344: Security Update for Microsoft Malware Protection Engine</li>
                </ul>
            </div>
        </div>
    );
};

export default Dashboard;
