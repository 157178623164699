import { Container, Row, Col } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Our Vision</h2>
                  <p>At Zeroday Ops Private Limited, our vision is to create a secure digital world where organizations can thrive without the fear of cyber threats. We aim to be the trusted partner for businesses globally, delivering unparalleled cybersecurity services that empower our clients to focus on their core operations with confidence. Through continuous learning, innovation, and a relentless pursuit of excellence, we strive to set new standards in the cybersecurity industry.</p>
                </div>
              }
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Decorative background" />
    </section>
  );
};
