import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import soc from '../../assets/img/soc.png';
import soc2 from '../../assets/img/soc2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';

const SocAssessment = () => {
    return (
        <>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        SOC 24x7 MONITORING SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Our SOC 24x7 Monitoring Services provide continuous, around-the-clock surveillance of your IT infrastructure to detect and respond to security threats in real time.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">SOC 24x7 Monitoring Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Security Operations Center (SOC) 24x7 monitoring services ensure that your organization's IT environment is constantly monitored for security incidents. By using advanced tools and techniques, our SOC team is able to detect, analyze, and respond to threats in real time, minimizing the impact of potential breaches.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={soc2} style={{ width: '250px' }} alt="SOC 24x7 Monitoring Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={soc} alt="Methodology" style={{ width: "95%", height: "95%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Continuous Threat Monitoring</li>
                                    <li>Real-Time Incident Response</li>
                                    <li>Advanced Threat Detection</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Log Analysis and Correlation</li>
                                    <li>Vulnerability Management</li>
                                    <li>Reporting and Alerts</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default SocAssessment;
