import React, { useState } from "react";
import axios from "axios";
import './recon.css';
import { NavBar } from "../../components/NavBar";
import { Footer } from "../../components/Footer";

const ReconSearch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleSearch = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.querySelector('.recon-input-search').focus();
    }
  };

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  const handleStart = async () => {
    if (!url) return;

    setIsLoading(true);

    try {
      const response = await axios.post('https://api.zerodayops.com/api/analysis', { domain: url });

      if (response.status === 200) {
        window.location.href = `/reconnaissance/${url}`;
      }
    } catch (error) {
      console.error("Error occurred during the POST request:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <main>
        {isLoading ? (
          <div className="loader-container" aria-live="polite">
            <div className="progress-bar">
              <div className="progress"></div>
            </div>
          </div>
        ) : (
          <div className="recon-search-container">
            <h2>Reconnaissance</h2>
            <p>Enter a domain:</p>
            <div className="recon-search-box">
              <button
                className="recon-btn-search"
                onClick={toggleSearch}
                aria-expanded={isOpen}
                aria-controls="recon-input"
                aria-label="Toggle search input"
              >
                🐞
              </button>
              <input
                id="recon-input"
                type="text"
                className={`recon-input-search ${isOpen ? "recon-open" : ""}`}
                placeholder="Enter URL: e.g. google.com"
                value={url}
                onChange={handleInputChange}
                aria-label="URL input"
              />
            </div>
            {url && (
              <button
                className="start-btn"
                onClick={handleStart}
                style={{color: "white"}}
                aria-label="Start reconnaissance"
              >
                Start
              </button>
            )}
            <section className="appSec-checklist">
              <h3 className="appSec-checklist-title">Reconnaissance Checks:</h3>
              <div className="appSec-checklist-items">
                <ul className="appSec-checklist-column">
                  <li>IP Info</li>
                  <li>Sub-Domains Enumeration</li>
                  <li>IP Enumeration</li>
                </ul>
                <ul className="appSec-checklist-column">
                  <li>Open Ports</li>
                  <li>Live Sub-Domains</li>
                  <li>Basic Network Vulnerabilities</li>
                </ul>
                <ul className="appSec-checklist-column">
                  <li>Traceroute</li>
                  <li>Security Headers Checks</li>
                  <li>SSL Check</li>
                </ul>
                <ul className="appSec-checklist-column">
                  <li>Sensitive Directories</li>
                  <li>Technologies Used</li>
                  <li>Enumerate all URLs</li>
                </ul>
              </div>
            </section>
          </div>
        )}
      </main>
      <Footer />
    </>
  );
};

export default ReconSearch;
