import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import cloud1 from '../../assets/img/cloud1.png';
import cloud2 from '../../assets/img/cloud2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';

const CloudSecurity = () => {
    return (
        <>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        CLOUD SECURITY SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Our Cloud Security Services ensure that your organization's cloud environments are secure, compliant, and resilient against modern cyber threats.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Cloud Security Services: Overview</h2>
                        <p className="appSec-overview-text">
                            As organizations increasingly move their operations to the cloud, ensuring the security of cloud environments has become critical. Our Cloud Security Services help protect your data, applications, and infrastructure from unauthorized access and cyber threats, while also ensuring compliance with industry standards and regulations.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={cloud1} style={{ width: '250px' }} alt="Cloud Security Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={cloud2} alt="Cloud Security Methodology" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Assessing Cloud Configuration and Security Settings</li>
                                    <li>Evaluating Identity and Access Management (IAM)</li>
                                    <li>Analyzing Data Encryption and Protection Mechanisms</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Testing for Compliance with Industry Standards</li>
                                    <li>Monitoring and Logging Cloud Activity</li>
                                    <li>Providing Incident Response and Remediation Strategies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default CloudSecurity;
