import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Our Services</h2>
              <p>We offer a comprehensive range of cybersecurity services designed to safeguard your digital assets. Our expertise includes Vulnerability Assessment and Penetration Testing (VAPT), penetration testing across web, network, Android, iOS, thick client, and thin client environments, as well as firewall security testing. Our specialized services also include Red Teaming, Security Operations Center (SoC) services, PCI DSS compliance, ISO compliance, and Governance, Risk, and Compliance (GRC) services. With our extensive suite of solutions, we ensure that all aspects of cybersecurity are addressed, providing you with peace of mind and a secure digital infrastructure.</p>
              <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                <div className="item">
                  <h5>Vulnerability Assessment and Penetration Testing (VAPT)</h5>
                </div>
                <div className="item">
                  <h5>Web Penetration Testing</h5>
                </div>
                <div className="item">
                  <h5>Network Penetration Testing</h5>
                </div>
                <div className="item">
                  <h5>Android Penetration Testing</h5>
                </div>
                <div className="item">
                  <h5>iOS Penetration Testing</h5>
                </div>
                <div className="item">
                  <h5>Source Code Audit</h5>
                </div>
                <div className="item">
                  <h5>Firewall Penetration Testing</h5>
                </div>
                <div className="item">
                  <h5>Thick Client Penetration Testing</h5>
                </div>
                <div className="item">
                  <h5>Red Teaming</h5>
                </div>
                <div className="item">
                  <h5>Security Operations Center (SoC)</h5>
                </div>
                <div className="item">
                  <h5>PCI DSS Compliance</h5>
                </div>
                <div className="item">
                  <h5>ISO Compliance</h5>
                </div>
                <div className="item">
                  <h5>Governance, Risk, and Compliance (GRC)</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Decorative background" />
    </section>
  );
};
