import React from 'react';
import './about.css';
import { NavBar } from '../../components/NavBar';
import profile from '../../assets/default.jpeg';
import { Footer } from '../../components/Footer';

function About() {
    return (
        <>
            <NavBar />
            <div className='about-container'>
                <div className="about-section">
                    <h1 style={{ fontWeight: "bold" }}>About Us</h1>
                    <br />
                    <p>Beyond Protection: ZeroDay Ops Safeguards Your Digital Future</p>
                    <br />
                    <p>Zeroday Ops Private Limited is a leading cybersecurity company dedicated to safeguarding organizations against financial and data loss. With a team of highly skilled experts, we deliver cutting-edge cybersecurity solutions tailored to meet the unique needs of our clients. We offer a wide range of cybersecurity services designed to protect and fortify your digital assets. Our services include Vulnerability Assessment and Penetration Testing (VAPT), comprehensive penetration testing for web, network, Android, iOS, thick client, and thin client environments, and firewall penetration testing. Additionally, we specialize in Red Teaming, Security Operations Center (SoC) services, PCI DSS compliance, ISO compliance, and Governance, Risk, and Compliance (GRC) services. Our extensive suite of offerings ensures that we can address all aspects of cybersecurity, providing our clients with the peace of mind that comes from knowing their digital infrastructure is secure.</p>
                    <br />
                    <p>At Zeroday Ops Private Limited, our vision is to create a secure digital world where organizations can thrive without the fear of cyber threats. We aim to be the trusted partner for businesses globally, delivering unparalleled cybersecurity services that empower our clients to focus on their core operations with confidence. Through continuous learning, innovation, and a relentless pursuit of excellence, we strive to set new standards in the cybersecurity industry</p>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div className="our-team-container">
                    <h2 style={{ textAlign: 'center', fontSize: 40, fontWeight: 'bold' }}>Our Team</h2>
                    <div className="about-row">
                        <div className="about-column">
                            <div className="about-card">
                                <img src={profile} alt="Sahil" style={{ width: '100%', height: '40vh', borderTopLeftRadius: "10%", borderTopRightRadius: "10%", objectFit: 'cover' }} />
                                <div className="about-container">
                                    <br />
                                    <h2>Sahil Kushwah</h2>
                                    <p className="about-title">Director</p>
                                    <p>As a Director at a cybersecurity company, he brings over three years of hands-on experience in the field, specializing in web application and network penetration testing. He holds the eWPTXv2 (Web Application Penetration Tester eXtreme) and eCPPT (Certified Professional Penetration Tester) certifications, which validate his expertise in identifying, analyzing, and mitigating complex security vulnerabilities. His skill set includes Red Teaming, Active Directory, Penetration Testing, Web Application Security, API Security, Mobile Application Security, Network Security, Secure Code Review, Phishing, and Firewall PT.</p>
                                    {/* <p>admin@zerodayops.com</p>
                                <p><button className="about-button">Contact</button></p> */}
                                </div>
                            </div>
                        </div>

                        <div className="about-column">
                            <div className="about-card">
                                <img src={profile} alt="Gautam" style={{ width: '100%', height: '40vh', borderTopLeftRadius: "10%", borderTopRightRadius: "10%", objectFit: 'cover' }} />
                                <div className="about-container">
                                    <br />
                                    <h2>Gautam Nangia</h2>
                                    <p className="about-title">Director</p>
                                    <p>As a Director at a leading cybersecurity firm, he brings over three years of comprehensive experience in the field, with a focus on web application and network penetration testing. He is certified as an eWPTXv2 (Web Application Penetration Tester eXtreme), showcasing his advanced expertise in identifying and addressing complex security vulnerabilities. His extensive skill set includes Red Teaming, Active Directory, Penetration Testing, Web Application Security, API Security, Mobile Application Security, Network Security, Secure Code Review, Phishing, and Firewall PT.<br /><br /></p>
                                    {/* <p>admin@zerodayops.com</p>
                                <br/>
                                <p><button className="about-button">Contact</button></p> */}
                                </div>
                            </div>
                        </div>

                        <div className="about-column">
                            <div className="about-card">
                                <img src={profile} alt="Kishan" style={{ width: '100%', height: '40vh', borderTopLeftRadius: "10%", borderTopRightRadius: "10%", objectFit: 'cover' }} />
                                <div className="about-container">
                                    <br />
                                    <h2>Kishan Rastogi</h2>
                                    <p className="about-title">Sales Lead</p>
                                    <p>He leverages a deep understanding of market dynamics and client needs to drive growth and achieve sales targets. With over three years of experience in the industry, he excels in developing and implementing effective sales strategies, building strong client relationships, and identifying new business opportunities. His expertise extends across sales management, client negotiations, strategic planning, and market analysis. His proactive approach, combined with excellent communication and leadership skills, enables him to lead his team in delivering exceptional results and exceeding client expectations.</p>
                                    {/* <p>sales@zerodayops.com</p>
                                <p><button className="about-button">Contact</button></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default About