import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './reconresult.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';

const ReconResult = () => {
  const { domain } = useParams(); // Get the domain from the URL parameter
  const [data, setData] = useState(null); // State to hold API response data
  const [isLoading, setIsLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.zerodayops.com/api/analysis/${domain}`);
        setData(response.data); // Store the API response data
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Failed to load data. Please try again.');
      } finally {
        setIsLoading(false); // Stop loading after data is fetched
      }
    };

    fetchData();
  }, [domain]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
      </div>
    );
  }

  if (!data) {
    return <div>Error loading data.</div>;
  }

  // Parsing JSON strings to objects
  const ipDetails = JSON.parse(data.ipDetails);
  const sslDetails = JSON.parse(data.sslDetails);
  const securityHeaders = JSON.parse(data.securityHeaders);
  const techStack = JSON.parse(data.techStack);
  const subdomains = JSON.parse(data.subdomains);
  const openPorts = JSON.parse(data.openPorts);
  const whoisOutput = JSON.parse(data.whoisOutput); // Parse whoisOutput
  const digOutput = JSON.parse(data.digOutput); // Parse digOutput

  // Extract the JSON portion from traceroute
  const tracerouteJsonString = data.traceroute.substring(data.traceroute.indexOf('{'));
  const tracerouteData = JSON.parse(tracerouteJsonString);

  const responseHeaders = data.responseHeaders.split('\r\n').filter(Boolean);

  return (
    <>
      <NavBar />
      <div className="recon-result">
        <br />
        <div className="recon-header">
          <h1>{data.domain}</h1>
        </div>

        <div className="recon-sections">
          <div className="recon-section Ip-Details">
            <h3>IP Details</h3>
            <ul>
              <li>IP: {ipDetails.ip}</li>
              <li>Network: {ipDetails.network}</li>
              <li>Version: {ipDetails.version}</li>
              <li>City: {ipDetails.city}</li>
              <li>Region: {ipDetails.region}</li>
              <li>Region Code: {ipDetails.region_code}</li>
              <li>Country: {ipDetails.country_name} ({ipDetails.country_code})</li>
              <li>Continent Code: {ipDetails.continent_code}</li>
              <li>Latitude: {ipDetails.latitude}</li>
              <li>Longitude: {ipDetails.longitude}</li>
              <li>Timezone: {ipDetails.timezone}</li>
              <li>UTC Offset: {ipDetails.utc_offset}</li>
              <li>Currency: {ipDetails.currency} ({ipDetails.currency_name})</li>
              <li>Languages: {ipDetails.languages}</li>
              <li>ASN: {ipDetails.asn}</li>
              <li>Organization: {ipDetails.org}</li>
            </ul>
          </div>

          <div className="recon-section ssl-details">
            <h3>SSL Enumeration</h3>
            <ul>
              <li>Domain: {sslDetails.domain}</li>
              <li>Issuer: {sslDetails.issuer}</li>
              <li>Valid From: {sslDetails.valid_from}</li>
              <li>Valid To: {sslDetails.valid_to}</li>
              <li>Serial Number: {sslDetails.serial_number}</li>
              <li>SHA256 Fingerprint: {sslDetails.fingerprint_sha256}</li>
              <li>SHA512 Fingerprint: {sslDetails.fingerprint_sha512}</li>
            </ul>
          </div>

          <div className="recon-section security-headers">
            <h3>Security Headers</h3>
            <ul>
              {Object.entries(securityHeaders.security_headers).map(([key, value]) => (
                <li key={key}>{key}: {value}</li>
              ))}
            </ul>
          </div>

          <div className="recon-section tech-stack">
            <h3>Tech Stack</h3>
            <ul>
              {techStack["https://" + domain].tech.map((item, index) => (
                <li key={index}>{item.name} {item.version ? `(v${item.version})` : ''}</li>
              ))}
              <li>Headers:</li>
              {techStack["https://" + domain].headers.map((header, index) => (
                <li key={index}>{header.name}: {header.value}</li>
              ))}
            </ul>
          </div>

          <div className="recon-section subdomains">
            <h3>Subdomains</h3>
            <ul>
              {subdomains.subdomains.map((subdomain, index) => (
                <li key={index}>{subdomain}</li>
              ))}
            </ul>
          </div>

          <div className="recon-section open-ports">
            <h3>Open Ports</h3>
            <ul>
              {openPorts.openPorts.map((port, index) => (
                <li key={index}>Port: {port} (Open)</li>
              ))}
            </ul>
          </div>

          <div className="recon-section response-headers">
            <h3>Response Headers</h3>
            <ul>
              {responseHeaders.map((header, index) => (
                <li key={index}>{header}</li>
              ))}
            </ul>
          </div>

          <div className="recon-section traceroute">
            <h3>Traceroute</h3>
            <ul>
              {tracerouteData.hops.map((hop, index) => (
                <li key={index}>Hop {hop.hop}: {hop.ip}</li>
              ))}
            </ul>
          </div>

          <div className="recon-section whois-output">
            <h3>WHOIS</h3>
            <ul>
              <li>Domain Name: {whoisOutput.domainName}</li>
              <li>Registrar: {whoisOutput.registrar}</li>
              <li>Creation Date: {whoisOutput.creationDate}</li>
              <li>Expiration Date: {whoisOutput.registrarRegistrationExpirationDate}</li>
              <li>Registrar URL: {whoisOutput.registrarUrl}</li>
              <li>Registrant Name: {whoisOutput.registrantName}</li>
              <li>Registrant Organization: {whoisOutput.registrantOrganization}</li>
              {/* Add more fields as necessary */}
            </ul>
          </div>

          <div className="recon-section dig-output">
            <h3>DIG</h3>
            <ul>
              {digOutput.ips.map((ip, index) => (
                <li key={index}>{ip}</li>
              ))}
            </ul>
          </div>

          {/* Include additional sections as necessary */}

        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReconResult;
