import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import network from '../../assets/img/network.png';
import network2 from '../../assets/img/network2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';

const NetworkSecurityAssessment = () => {
    return (
        <>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        NETWORK SECURITY ASSESSMENT SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        The network security assessment results in a detailed report highlighting all identified vulnerabilities and providing recommendations for resolving issues and optimizing the security of your network infrastructure.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Network Security Assessment Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Ensuring the security of your network is crucial to protect sensitive data and maintain the integrity of your information systems. Weaknesses in network configurations or improper implementations can expose your network to unauthorized access and cyber threats. Regular monitoring and thorough network security assessments are essential to safeguard your organization against these risks.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={network} style={{ width: '250px' }} alt="Network Security Assessment Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={network2} alt="Network Security Methodology" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Identifying Unauthorized Access Points</li>
                                    <li>Evaluating Firewall Configurations</li>
                                    <li>Analyzing Network Traffic</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Testing Intrusion Detection Systems (IDS)</li>
                                    <li>Assessing Network Segmentation</li>
                                    <li>Evaluating VPN Security</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default NetworkSecurityAssessment;
