import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import redTeam from '../../assets/img/redteam.png';
import redTeam2 from '../../assets/img/redteaming2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';

const RedTeamingAssessment = () => {
    return (
        <>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        RED TEAMING SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Our Red Teaming services provide a comprehensive assessment of your organization’s security posture by simulating real-world attacks to identify vulnerabilities and test your defenses.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Red Teaming Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Red Teaming involves emulating the tactics, techniques, and procedures of real-world adversaries to challenge your organization's defenses. This approach provides valuable insights into the effectiveness of your security measures and identifies areas for improvement to better protect your assets against sophisticated attacks.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={redTeam} style={{ width: '250px' }} alt="Red Teaming Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={redTeam2} alt="Methodology" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Reconnaissance and Information Gathering</li>
                                    <li>Identifying Potential Attack Vectors</li>
                                    <li>Simulating Social Engineering Attacks</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Testing Physical Security Measures</li>
                                    <li>Exploiting Identified Vulnerabilities</li>
                                    <li>Reporting and Providing Mitigation Recommendations</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default RedTeamingAssessment;
