import React from 'react';
import contact from '../assets/img/3786012.jpg';
import axios from 'axios';
import { useRef } from 'react';

export const Contact = () => {
  const name = useRef();
  const email = useRef();
  const company = useRef();
  const message = useRef();

  const submitHandler = async (e) => {
    e.preventDefault();
    const newContact = {
      name: name.current.value,
      email: email.current.value,
      company: company.current.value,
      message: message.current.value,
    };

    try {
      await axios.post(`https://api.zerodayops.com/api/contact`, newContact);
      window.location.reload();
    } catch (err) {

    }
  };

  return (
    <div className="contact-background">
      <div className="left-content-contact">
        <img
          src={contact}
          alt="Contact Us"
          className="contact-image"
          style={{ borderRadius: '30px', height: '92%', marginBottom: '10%' }}
        />
      </div>
      <div className="contact-container">
        <div className="contact-screen">
          <div className="contact-screen-header">
            <div className="contact-screen-header-left">
              <div className="contact-screen-header-button close"></div>
              <div className="contact-screen-header-button maximize"></div>
              <div className="contact-screen-header-button minimize"></div>
            </div>
            <div className="contact-screen-header-right">
              <div className="contact-screen-header-ellipsis"></div>
              <div className="contact-screen-header-ellipsis"></div>
              <div className="contact-screen-header-ellipsis"></div>
            </div>
          </div>
          <div className="contact-screen-body">
            <div className="contact-screen-body-item left">
              <div className="contact-app-title">
                <span>CONTACT US</span>
              </div>
              <div className="contact-app-contact">CONTACT INFO: admin@zerodayops.com</div>
            </div>
            <div className="contact-screen-body-item">
              <div className="contact-app-form">
                <form onSubmit={submitHandler}>
                  <div className="contact-app-form-group">
                    <input
                      className="contact-app-form-control"
                      placeholder="NAME"
                      ref={name}
                      required
                      aria-label="Name"
                    />
                  </div>
                  <div className="contact-app-form-group">
                    <input
                      className="contact-app-form-control"
                      placeholder="EMAIL"
                      ref={email}
                      type="email"
                      required
                      aria-label="Email"
                    />
                  </div>
                  <div className="contact-app-form-group">
                    <input
                      className="contact-app-form-control"
                      placeholder="Company"
                      ref={company}
                      required
                      aria-label="Company"
                    />
                  </div>
                  <div className="contact-app-form-group contact-message">
                    <input
                      className="contact-app-form-control"
                      placeholder="MESSAGE"
                      ref={message}
                      required
                      aria-label="Message"
                    />
                  </div>
                  <div className="contact-app-form-group contact-buttons">
                    <button className="contact-app-form-button" type="submit">SEND</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
