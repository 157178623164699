import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";

export const NavBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState('home');
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!servicesDropdownOpen);
  };

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="ZeroDay Ops Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              href="/"
              className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('home')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="/reconnaissance"
              className={activeLink === 'reconnaissance' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('reconnaissance')}
            >
              Reconnaissance
            </Nav.Link>

            {/* Services Dropdown */}
            <div
              className={`nav-item dropdown ${servicesDropdownOpen ? 'open' : ''}`}
              onClick={toggleServicesDropdown}
            >
              <Nav.Link
                className={activeLink === 'services' ? 'active navbar-link' : 'navbar-link'}
                onClick={() => onUpdateActiveLink('services')}
              >
                Services
              </Nav.Link>
              {servicesDropdownOpen && (
                <div className="dropdown-content">
                  <div className="row">
                    <Link to="/services/appsec">Application Security</Link>
                    <Link to="/services/network-security">Network Security</Link>
                    <Link to="/services/firewall-security">Firewall Security</Link>
                    <Link to="/services/wireless-security">Wireless Security</Link>
                    <Link to="/services/red-teaming">Red Teaming</Link>
                    <Link to="/services/soc24x7">SOC 24x7</Link>
                    <Link to="/services/pci-dss">PCI DSS</Link>
                    <Link to="/services/cloud-security">Cloud Security</Link>
                    {/* <Link to="/services/penetration-testing">Penetration Testing</Link> */}
                    {/* <Link to="/services/compliance-audit">Compliance Audit</Link> */}
                  </div>
                </div>
              )}
            </div>

            <Nav.Link
              href="https://zerodayops.medium.com"
              className={activeLink === 'blog' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('blog')}
            >
              Blog
            </Nav.Link>

            <Nav.Link
              href="/about"
              className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('about')}
            >
              About Us
            </Nav.Link>
            <Nav.Link
              href="/contact"
              className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'}
              onClick={() => onUpdateActiveLink('contact')}
            >
              Contact Us
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            {/* <div className="social-icon">
              <a href="https://www.linkedin.com/company/zeroday-ops/" aria-label="ZeroDay Ops LinkedIn">
                <img src={navIcon1} alt="LinkedIn Icon" />
              </a>
              <a href="#"><img src={navIcon2} alt="Social Icon" /></a>
              <a href="https://www.instagram.com/zerodayops" aria-label="ZeroDay Ops Instagram">
                <img src={navIcon3} alt="Instagram Icon" />
              </a>
            </div> */}
            {/* <HashLink to='#connect'> */}
            <Link to="/login"><button className="vvd"><span>Login</span></button></Link>
            {/* </HashLink> */}
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
