import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import firewall from '../../assets/img/firwall1.png';
import firewall2 from '../../assets/img/firewall2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';

const FirewallSecurityAssessment = () => {
    return (
        <>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        FIREWALL SECURITY TESTING SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Our Firewall Security Testing services ensure that your firewall is configured correctly and effectively protects your network from unauthorized access and threats.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Firewall Security Testing Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Firewalls are a critical component of network security, acting as the first line of defense against cyber threats. However, misconfigurations or outdated settings can leave your network vulnerable. Our Firewall Security Testing services evaluate the effectiveness of your firewall, ensuring it is configured to block unauthorized access and potential attacks.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={firewall} style={{ width: '250px' }} alt="Firewall Security Testing Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={firewall2} alt="Firewall Security Methodology" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Reviewing Firewall Rules and Policies</li>
                                    <li>Evaluating Access Control Lists (ACLs)</li>
                                    <li>Testing Firewall Bypass Techniques</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Analyzing Firewall Logs</li>
                                    <li>Assessing Firewall Firmware and Updates</li>
                                    <li>Validating Firewall Redundancy and Failover Mechanisms</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default FirewallSecurityAssessment;
